import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Footer from './Footer';
import LoadingButton from '@mui/lab/LoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { cms } from '../config';

const PasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffff00',
      },
      secondary: {
        main: '#ffff00',
      },
    },
  });

  const resetPassword = async () => {
    if ( email !== '') {
      await axios.post(`${cms.url}/auth/password/request`, {
        email: email,
        reset_url: `${window.location.origin}/reset-password`
      }).then((res) => {
        if (res.status === 200) {
          setOpen(true);
        }
        setEmail('');
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      setOpenError(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          {/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}> */}
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} variant="filled">
                We've sent you a secure link to reset your password
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={2000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }} variant="filled">
                The email field is mandatory
              </Alert>
            </Snackbar>
            <LoadingButton
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              onClick={async () => {
                setLoading(true);
                await resetPassword();
                setLoading(false);
              }}
              loading={loading}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default PasswordRequest;
