/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import Login from './components/Login';
import PasswordReset from './components/PasswordReset';
import PasswordRequest from './components/PasswordRequest';

const App = () => {
  return (
    <div className="App">
        <BrowserRouter>
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/request-password" element={<PasswordRequest />} />
        </Routes>
        </BrowserRouter>
    </div>
  );
};

export default App;
