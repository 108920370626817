import Typography from '@mui/material/Typography';

const Footer = () => {

  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
      Not authenticated
    </Typography>
  );
}

export default Footer;