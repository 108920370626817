import React, { useState} from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useLocation} from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import queryString from 'query-string';
import { cms } from '../config';
import Footer from './Footer';

const PasswordReset = () => {
  const [password, setPassword] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openEmptyEmail, setOpenEmptyEmail] = useState(false);
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const token = qs.token;
  const [loading, setLoading] = useState(false);

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const handleCloseEmptyEmail = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenEmptyEmail(false);
  };

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffff00',
      },
      secondary: {
        main: '#ffff00',
      },
    },
  });

  const sendResetPassword = async () => {
    if ( password !== '') {
      await axios.post(`${cms.url}/auth/password/reset`, { token: token, password: password }).then((res) => {
        if (res.status === 200) {
          setOpenSuccess(true);
        }
        setPassword('');
      })
      .catch((error) => {
        setOpenError(true);
        setPassword('');
      });
    } else {
      setOpenEmptyEmail(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          {/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}> */}
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoFocus
              onChange={(event) => setPassword(event.target.value)}
              value={password}
            />
            <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleCloseSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }} variant="filled">
              Password reset successful
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={2000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }} variant="filled">
              Something went wrong!
              </Alert>
            </Snackbar>
            <Snackbar open={openEmptyEmail} autoHideDuration={2000} onClose={handleCloseEmptyEmail} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleCloseEmptyEmail} severity="error" sx={{ width: '100%' }} variant="filled">
              The email field is mandatory
              </Alert>
            </Snackbar>
            <LoadingButton
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              onClick={async () => {
                setLoading(true);
                await sendResetPassword();
                setLoading(false);
              }}
              loading={loading}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        <Footer />
      </Container>
    </ThemeProvider>
  );

}

export default PasswordReset;
